.file-upload-input {
  margin-top: 20px;
  border: 4px dashed #1FB264;
  position: relative;
}
.image-upload-cont {
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
  }
}