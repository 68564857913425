.baby-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 150px;
  border-radius: 10px;
}
.timeline-video {
  position: relative;
  width: 100%;
}
.vertical-timeline::before {
  left: 28px;
}
.vertical-timeline-element-icon {
  top: 10px;
  left: 15px;
  width: 30px;
  height: 30px;
}
.vertical-timeline {
  overflow: hidden;
}
.timeline-icon {
  width: 40px;
  top: 10px;
  position: absolute;
  left: calc(50% - 20px);
}

@media screen and (max-width: 1169px)  {
  .timeline-icon {
    position: absolute;
    width: 22px;
    top: 5px;
    left: calc(50% - 11px);
  } 
}