@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Montserrat:wght@100;400&display=swap');
.App {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: 'Crimson Text', serif;
}

.header {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 60px;
  font-family: 'Montserrat', sans-serif;
  .header-remove-secret {
    position: absolute;
    background: beige;
    box-sizing: border-box;
    color: #282c34;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    top: 0.8em;
    right: 0.8em;
    font-size: 1.2rem;
    padding: 0 0 0 1px;
  }
  img {
    width: 25px;
  }
  .header-upload {
    position: absolute;
    background: beige;
    box-sizing: border-box;
    color: #282c34;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    top: 0.8em;
    left: 0.8em;
    font-size: 1.2rem;
    padding: 0 0 0 1px;
  }
}

.bod {
  background-color: beige;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;

  .mider {
    width: 456px;
    height: 136px;
    padding: 5px 20px;
    border: 2px solid black;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 0.9fr auto;
    box-shadow: 10px 10px 0px black;
    box-sizing: content-box;
    left: -6px;
    position: relative;
    top: -6px;
  }

  .centerer {
    align-self: center;
    justify-self: center;
  }
  .input-cont {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-items: center;
    display: flex;
    position: relative;
    top: -1px;
  }
  .input-secret {
    width: calc(100% - 3px);
    height: auto;
    font-size: 1.3rem;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    box-shadow: 2px 2px 0px black;
    box-sizing: border-box;
    padding: 0px 5px;
  }
  .input-secret:focus {
    outline: none;
  }

  .bod-button {
    background-color: #282c34;
    border: none;
    border-radius: 0px;
    color: white;
    height: 30px;
    transition: 0.5s ease;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    padding-top: 5px;
    box-sizing: border-box;
  }

  .bod-button:active,
  .bod-button:hover {
    outline: none;
    background: white;
    color: #282c34;
  }
}

.footer {
  color: white;
  position: fixed;
  transform: rotate(90deg);
  left: -80px;
  bottom: 100px;
  width: 230px;
  font-size: 0.8rem;
  font-family: 'Montserrat', sans-serif;

  .footer-managed {
    padding: 2px 15px;
  }
}

@media screen and (max-width: 1169px) {
  .footer {
    left: -100px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    left: -105px;
  }
}
